$(document).ready(function () {
    Vue.use(VueMask.VueMaskPlugin);

    var app = new Vue({
        el: "#app",
        data: {
            isMenuOpened: false,
            form: {
                notlike: {
                    formName: "Ничего не понравилось?",
                    name: "",
                    phone: "",
                    message: "",
                    checkbox: true,
                    attemptSubmit: false
                },
                contacts: {
                    formName: "Доп. вопрос",
                    name: "",
                    phone: "",
                    checkbox: true,
                    attemptSubmit: false
                },
                order: {
                    formName: "Заказ букета",
                    formDesc: "",
                    name: "",
                    phone: "",
                    pay: "cash",
                    product: {},
                    checkbox: true,
                    attemptSubmit: false
                }
            },
            productsFilter: "all",
            products: [
                {
                    pictureId: 1,
                    title: "Букет &laquo;Стандарт&raquo;",
                    desc: "28&ndash;34 ягод (1 кг), все ягоды&nbsp;&mdash; свежая клубника, украшенная бумагой (цвет бумаги на&nbsp;Ваш выбор)",
                    oldPrice: "1990",
                    price: "1690",
                    filters: ["all"]
                },
                {
                    pictureId: 2,
                    title: "Букет &laquo;Киви&raquo;",
                    desc: "27&ndash;35 ягод (1кг) из&nbsp;которых 11 ягод покрыты белым, бельгийским шоколадом посыпанные дробленным, соленным арахисом",
                    oldPrice: "2990",
                    price: "2490",
                    filters: ["all"]
                },
                {
                    pictureId: 3,
                    title: "Букет &laquo;Ореол&raquo;",
                    desc: "31&ndash;37 ягод (1 кг), белый бельгийский шоколад Callebaut Chocolate и&nbsp;веточки гипсофилы",
                    oldPrice: "3350",
                    price: "2850",
                    filters: ["all"]
                },
                {
                    pictureId: 4,
                    title: "Букет &laquo;Герел&raquo;",
                    desc: "54&ndash;63 ягод (2 кг) из&nbsp;них 15 покрыты бельгийским молочным шоколадом, украшена голубикой",
                    oldPrice: "5090",
                    price: "4290",
                    filters: ["all", "hit"]
                },
                {
                    pictureId: 5,
                    title: "Букет &laquo;Классический&raquo;",
                    desc: "23&ndash;28 ягод (0,7 кг) мята и&nbsp;голубика, украшенная бумагой (цвет бумаги на&nbsp;Ваш выбор)",
                    oldPrice: "2990",
                    price: "2450",
                    filters: ["all"]
                },
                {
                    pictureId: 6,
                    title: "Букет &laquo;Нежный&raquo;",
                    desc: "54&ndash;63 ягод (2 кг) полностью покрытый белым и&nbsp;молочным бельгийским шоколадом!",
                    oldPrice: "5690",
                    price: "4690",
                    filters: ["all"]
                },
                {
                    pictureId: 7,
                    title: "Букет &laquo;Зебра&raquo;",
                    desc: "70&ndash;80 ягод (более <nobr>2-х</nobr> кг) полностью покрытые белым и&nbsp;молочным шоколадом, второй ряд посыпан дробленым, соленным арахисом",
                    oldPrice: "5990",
                    price: "4990",
                    filters: ["all"]
                },
                {
                    pictureId: 8,
                    title: "Букет &laquo;ШокоБум&raquo;",
                    desc: "28&ndash;34 ягод (1 кг) полностью покрытый молочным шоколадом Callebaut Chocolate",
                    oldPrice: "4390",
                    price: "3590",
                    filters: ["all"]
                },
                {
                    pictureId: 9,
                    title: "Букет &laquo;Плеяды&raquo;",
                    desc: "7 красных роз, 24&ndash;29 ягод (0,8 кг) и&nbsp;украшена ежевикой, украшен бумагой (цвет бумаги на&nbsp;Ваш выбор)",
                    oldPrice: "4290",
                    price: "3450",
                    filters: ["all"]
                },
                {
                    pictureId: 10,
                    title: "Букет &laquo;Звезда&raquo;",
                    desc: "28-34 ягод (1&nbsp;кг), все ягоды&nbsp;&mdash; свежая клубника, украшенная бумагой (цвет бумаги на&nbsp;Ваш выбор)",
                    oldPrice: "3990",
                    price: "3250",
                    filters: ["all", "hit"]
                },
                {
                    pictureId: 11,
                    title: "Букет &laquo;Нелли&raquo;",
                    desc: "28-34 ягод (1&nbsp;кг), все ягоды&nbsp;&mdash; свежая клубника, украшенная бумагой (цвет бумаги на&nbsp;Ваш выбор)",
                    oldPrice: "3490",
                    price: "2990",
                    filters: ["all"]
                },
                {
                    pictureId: 12,
                    title: "Букет &laquo;Воздушный&raquo;",
                    desc: "28-34 ягод (1&nbsp;кг), все ягоды&nbsp;&mdash; свежая клубника, украшенная бумагой (цвет бумаги на&nbsp;Ваш выбор)",
                    oldPrice: "3990",
                    price: "3250",
                    filters: ["all"]
                },
                {
                    pictureId: 13,
                    title: "Букет &laquo;Одинокая роза&raquo;",
                    desc: "1&nbsp;роза и&nbsp;24&nbsp;ягоды (0,7&nbsp;кг) , украшен бумагой (цвет бумаги на&nbsp;Ваш выбор)",
                    oldPrice: "2590",
                    price: "2090",
                    filters: ["all"]
                },
                {
                    pictureId: 14,
                    title: "Букет &laquo;Ягодный бунт&raquo;",
                    desc: "30-36 ягод (1&nbsp;кг) и&nbsp;250 грамм голубики, украшен бумагой",
                    oldPrice: "2990",
                    price: "2590",
                    filters: ["all"]
                },
                {
                    pictureId: 15,
                    title: "Букет &laquo;Медуза&raquo;",
                    desc: "1&nbsp;роза и&nbsp;24&nbsp;ягоды (0,7&nbsp;кг), украшен бумагой (цвет бумаги на&nbsp;Ваш выбор)",
                    oldPrice: "3990",
                    price: "3250",
                    filters: ["all"]
                },
                {
                    pictureId: 16,
                    title: "Шкатулка любимой",
                    desc: "25&nbsp;роз и&nbsp;15&nbsp;ягод в&nbsp;шоколаде в&nbsp;упаковке в&nbsp;форме сердца",
                    oldPrice: "6300",
                    price: "4900",
                    filters: ["all", "premium"]
                },
                {
                    pictureId: 17,
                    title: "Букет &laquo;Tats&raquo;",
                    desc: "33&nbsp;розы и&nbsp;29-33 ягод (1&nbsp;кг ) из&nbsp;которых 10&nbsp;покрыты молочным шоколадом",
                    oldPrice: "6990",
                    price: "6200",
                    filters: ["all", "premium"]
                },
                {
                    pictureId: 18,
                    title: "Букет &laquo;Liya&raquo;",
                    desc: "Описание",
                    oldPrice: "9990",
                    price: "8900",
                    filters: ["all", "premium"]
                },
                {
                    pictureId: 19,
                    title: "Букет &laquo;Екатерина&raquo;",
                    desc: "Описание",
                    oldPrice: "7890",
                    price: "6890",
                    filters: ["all"]
                },
                {
                    pictureId: 20,
                    title: "Коробочка с&nbsp;клубникой",
                    desc: "8&nbsp;шт., оформление и&nbsp;тип шоколада можно обсудить с&nbsp;менеджером",
                    oldPrice: "900",
                    price: "500",
                    filters: ["all", "hit"]
                }
            ]
        },
        methods: {
            /**
             * Функция открытия модального окна
             * @param event Событие
             * @param el Название модального окна
             */
            openPopup: function(el, event) {
                event.preventDefault();

                switch (el) {
                    default:
                        $.fancybox.open($(".popup-" + el), {
                            touch: false,
                        });
                        break;
                }
            },
            /**
             * Скролл к блоку
             * @param to | Блок к которому нужно скроллить
             * @param event
             */
            scrollTo: function (to, event) {
                this.isMenuOpened = false;

                if (event) event.preventDefault();
                var top = $(to).offset().top + 1;

                if($(document).innerWidth() <= 959) {
                    top = top - $(".header").innerHeight();
                }

                $("body, html").animate({scrollTop: top}, 500);
            },
            /**
             * Ввод телефона
             * @param form
             */
            enterPhone: function (form) {
                (form.phone.length == 0) ? form.phone = " " : form.phone
            },
            isImportantValid: function (val) {
                return val.length > 0;
            },
            /**
             * Проверка на валидность email
             * @param email
             * @return {boolean}
             */
            isEmailValid: function (email) {
                return /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/.test(email) && email.length > 0
            },
            /**
             * Валидация имени
             * @param name
             * @return {boolean}
             */
            isNameValid: function (name) {
                return !/^[A-Za-z0-9]+$/.test(name) && name.length >= 2
            },
            /**
             * Валидация телефона
             * @param phone
             * @return {boolean}
             */
            isPhoneValid: function (phone) {
                return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/.test(phone) && phone.length > 0
            },
            /**
             * Валидация
             * @param formName
             * @return {boolean}
             */
            validate: function (formName) {
                this.form[formName].attemptSubmit = true;
                var valid = "";

                switch (formName) {
                    default:
                            valid = this.isNameValid(this.form[formName].name) && this.isPhoneValid(this.form[formName].phone) && this.form[formName].checkbox;
                        break;
                }

                if(valid) {
                    this.sendForm(this.form[formName]);
                } else {
                    return false;
                }
            },
            /**
             * Отправка формы
             * @param form
             */
            sendForm: function (form) {
                if(form.pay == "card") {
                    $.fancybox.close();
                    $(".form-tinkoff").submit();
                    return;
                }

                axios({
                    method: "post",
                    url: "./sender.php",
                    data: form,
                    config: { headers: {"Content-Type": "application/x-www-form-urlencoded" }}
                }).then(function (response) {
                    window.location.replace("./thanks.html");
                }).catch(function (response) {});
            },
            /**
             * Фильтрация товаров
             * @param filter
             */
            productsFiltering: function (filter) {
                this.productsFilter = filter;

                if($(document).innerWidth() <= 1024) {
                    $(".products-items").slick("slickUnfilter");
                    if(filter !== "all") {
                        $(".products-items").slick("slickFilter", ".products-items__item_filter_" + filter);
                    }
                }
            }
        },
        computed: {
            getProducts: function () {
                var products = this.products;
                var filter = this.productsFilter;

                var result = [];

                if($(document).innerWidth() > 1024) {
                    if (filter == "all") {
                        result = products;
                    } else {
                        var i;
                        for (i = 0; i < products.length; i++) {
                            var product = products[i];

                            if (product.filters.indexOf(filter) !== -1) {
                                result.push(product);
                            }
                        }
                    }
                } else {
                    result = products;
                }

                return result;
            }
        },

    });

    $(".reviews-slider").slick({
        dots: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        adaptiveHeight: true,
        prevArrow: '<div class="slider__arrow slider__arrow-prev reviews-slider__arrow-prev"></div>',
        nextArrow: '<div class="slider__arrow slider__arrow-next reviews-slider__arrow-next"></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $(".questions-item").click(function () {
       $this = $(this);
       $answer = $this.find(".questions-item__body");

       if($this.hasClass("questions-item_active")) {
           $this.removeClass("questions-item_active");
           $answer.slideUp();
       } else {
           $this.addClass("questions-item_active");
           $answer.slideDown();
       }
    });

    var menu_selector = ".menu-list";
    function onScroll() {
        var scroll_top = $(document).scrollTop();
        $(menu_selector + " a").each(function() {
            var hash = $(this).attr("href");
            var target = $(hash);
            if (target.position().top <= scroll_top && target.position().top + target.outerHeight() > scroll_top) {
                $(menu_selector + " .menu-list__item-link").removeClass("menu-list__item-link_active");
                $(this).addClass("menu-list__item-link_active");
            } else {
                $(this).removeClass("menu-list__item-link_active");
            }
        });

        var promoHeight = $(".promo").innerHeight();
        var $menu = $(".menu");
        if($(document).innerWidth() > 959) {
            if (scroll_top > promoHeight) {
                $menu.fadeIn();
            } else {
                $menu.hide();
            }
        }
    }

    $(document).on("scroll", onScroll);

    // About
    if($(document).innerWidth() <= 840) {
        $(".about-items").slick({
            dots: true,
            adaptiveHeight: true,
            slidesToShow: 2,
            prevArrow: '<div class="slider__arrow slider__arrow-prev about-items__arrow-prev"></div>',
            nextArrow: '<div class="slider__arrow slider__arrow-next about-items__arrow-next"></div>',
            responsive: [
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        adaptiveHeight: true,
                        dots: true
                    }
                }
            ]
        });
    }

    if($(document).innerWidth() <= 1024) {
        $(".products-items").slick({
            dots: true,
            adaptiveHeight: true,
            slidesToShow: 3,
            prevArrow: '<div class="slider__arrow slider__arrow-prev about-items__arrow-prev"></div>',
            nextArrow: '<div class="slider__arrow slider__arrow-next about-items__arrow-next"></div>',
            responsive: [
                {
                    breakpoint: 840,
                    settings: {
                        slidesToShow: 2,
                        adaptiveHeight: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        adaptiveHeight: true,
                        dots: true
                    }
                }
            ]
        });
    }
    if($(document).innerWidth() <= 640) {
        $(".steps-items").slick({
            dots: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            prevArrow: '<div class="slider__arrow slider__arrow-prev about-items__arrow-prev"></div>',
            nextArrow: '<div class="slider__arrow slider__arrow-next about-items__arrow-next"></div>'
        });
    }

    $("iframe").on('load', function() {
        var height = this.contentWindow.document.body.scrollHeight;
        $("iframe").height(height);
    });
});